/* eslint-disable */
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      hasSentConsent: state => state.gtmEvents.hasSentConsent,
      pendingEvents: state => state.gtmEvents.pendingEvents,
    }),
  },
  methods: {
    gtmPush(event) {
      if (!this.hasSentConsent) {
        this.$store.commit('gtmEvents/addPendingEvent', event);
      } else {
        this.$gtm.push(event);
      }
    },
    sendPendingEvents() {
      if (this.pendingEvents && this.pendingEvents.length) {
        while (this.pendingEvents.length) {
          this.$store.commit('gtmEvents/shiftAndPushPendingEvents');
        }
      }
    },
  },
};
